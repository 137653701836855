import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Avatar,
  Chip,
  IconButton,
  Switch,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { ReactComponent as DropdownIcon } from '../theme/images/deopdownIcon.svg';
import Layout from '../components/Layout/Layout';
import RoleTabsUI from '../sections/@dashboard/Roles/RolesTabs';
import { getManageRoles, addRoleRequest } from '../network/apis';

const validationSchema = yup.object({
  roleNameEn: yup
    .string()
    .required('Role Name is required')
    .matches(/^[a-zA-Z0-9\s]*$/, 'Wrong format'),
  // jobId: yup.string().required('This field is required'),
  // employees: yup.array().of(yup.string()).required('This field is required'),
});

const RoleForm = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const { roleId } = useParams();
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [rolesData, setRolesData] = useState({});
  const [personName, setPersonName] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [initialValues, setInitialValues] = useState({
    roleNameEn: '',
    jobId: '',
    employees: [],
    active: true,
  });

  useEffect(() => {
    getManageRoles(roleId || '').then((res) => {
      setRolesData(res?.data);
      setJobs(res?.data?.JOBS_LIST || []);

      if (roleId) {
        const jobId = res?.data?.DATA?.jobId;
        const employeeList = res?.data?.DATA?.userList.map((user) => user.userId.toString()) || [];
        setInitialValues({
          roleNameEn: res?.data?.DATA?.nameEn || '',
          jobId: jobId || '',
          employees: employeeList,
          active: res?.data?.DATA?.active === '1',
        });
        setPersonName(employeeList);
        if (jobId) {
          const selectedJob = res?.data?.JOBS_LIST.find((job) => job.id === jobId);
          setEmployees(selectedJob?.users || []);
        }
      } else {
        setInitialValues({
          roleNameEn: '',
          jobId: '',
          employees: [],
          active: true,
        });
        setPersonName([]);
      }
    });
  }, [roleId]);

  const handleJobChange = async (event) => {
    const { value } = event.target;
    const selectedJob = jobs.find((job) => job.id === value);
    if (selectedJob) {
      setEmployees(selectedJob?.users || []);
    } else {
      setEmployees([]);
    }
  };

  const handleMultiSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === 'string' ? value.split(',') : value);
  };

  const handleDeleteChip = (chipToDelete) => (event) => {
    event.stopPropagation();
    setPersonName((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleSubmit = (values, { setTouched }) => {
    setLoading(true);
    const permissions = {};
    Object.keys(selectedPermissions).forEach((functionId) => {
      permissions[functionId] = selectedPermissions[functionId];
    });
    const roleData = {
      roleData: {
        nameEn: values.roleNameEn,
        nameAr: values.roleNameEn,
        active: values.active ? '1' : '0',
        jobId: values.jobId,
        roleId: roleId ? parseInt(roleId, 10) : 0,
      },
      permissions,
      users: personName.map((name) => parseInt(name, 10)),
      action: roleId ? 'U' : 'I',
    };
    addRoleRequest(roleData)
      .then((response) => {
        setLoading(false);
        console.log(response.data.STATUS);
        if (response.data.STATUS === 'SUCCESS') {
          toast.success(response.data.MESSAGE);
          navigate(`/dashboard/roles`);
        } else if (response.data.STATUS === 'EXIST') {
          toast.error(response.data.MESSAGE);
        } else {
          console.log(response.data);
          toast.error(response.data.MESSAGE);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.data.MESSAGE);
      });

    // Set the touched state for employees field to show the error message
    setTouched({
      employees: true,
    });
  };

  const handleCancel = (confirm) => {
    setOpenCancelDialog(true);
    if (confirm && roleId) {
      navigate('/dashboard/roles');
    }
  };

  const handleCancelDialogClose = (confirm) => {
    setOpenCancelDialog(false);
    if (confirm) {
      navigate('/dashboard/roles');
    }
  };

  const getInitials = (name = '') => {
    if (!name) return '';
    return name
      .split(' ')
      .map((n) => n[0])
      .join('')
      .substring(0, 2)
      .toUpperCase();
  };

  return (
    <Layout>
      <Container>
        <Typography sx={{ mb: 3 }} variant="h5" gutterBottom>
          {roleId ? (!isRtl ? 'Edit Role' : ' تعديل الصلاحية') : !isRtl ? 'New Role' : ' اضافة صلاحية'}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" gutterBottom>
                    {t('Roles.roleName')} <span style={{ color: 'red' }}> * </span>
                  </Typography>
                  <TextField
                    label=""
                    name="roleNameEn"
                    fullWidth
                    value={values.roleNameEn}
                    onChange={handleChange}
                    error={touched.roleNameEn && Boolean(errors.roleNameEn)}
                    helperText={touched.roleNameEn && errors.roleNameEn}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography variant="body1" gutterBottom>
                    {t('Roles.jobTitle')} <span style={{ color: 'red' }}> * </span>
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="jobs"
                      displayEmpty
                      sx={{
                        paddingRight: '10px',
                        paddingLeft: '10px',
                        borderRadius: '10px',
                      }}
                      id="jobId"
                      name="jobId"
                      label="Jobs"
                      value={values.jobId}
                      onChange={(e) => {
                        handleChange(e);
                        handleJobChange(e);
                      }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span style={{ color: '#9e9e9e' }}> {t('General.select')}</span>;
                        }
                        return isRtl
                          ? jobs?.find((option) => option.id === selected)?.nameAr
                          : jobs?.find((option) => option.id === selected)?.nameEn;
                      }}
                      error={touched.jobId && Boolean(errors.jobId)}
                      IconComponent={() => <DropdownIcon style={{ width: '15px', height: '15px' }} />}
                    >
                      {jobs &&
                        jobs.map((job) => (
                          <MenuItem key={job.id} value={job.id}>
                            {isRtl ? job.nameAr : job.nameEn}
                          </MenuItem>
                        ))}
                    </Select>
                    {touched.jobId && errors.jobId && (
                      <Typography color="error" variant="body2">
                        {errors.jobId}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <Typography variant="body1" gutterBottom>
                      {t('Roles.employees')} <span style={{ color: 'red' }}> * </span>
                    </Typography>
                    <Select
                      multiple
                      displayEmpty
                      sx={{
                        paddingRight: '10px',
                        paddingLeft: '10px',
                        borderRadius: '10px',
                      }}
                      value={personName}
                      onChange={handleMultiSelectChange}
                      input={<OutlinedInput label={t('Roles.employees')} />}
                      IconComponent={() => <DropdownIcon style={{ width: '15px', height: '15px' }} />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <Typography color="textSecondary">{t('General.select')}</Typography>;
                        }
                        return (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => {
                              const employee = employees.find((emp) => emp.userId.toString() === value.toString());
                              const displayName = isRtl ? employee?.firstNameAr : employee?.firstNameEn;
                              return (
                                employee && (
                                  <Chip
                                    key={value}
                                    label={displayName}
                                    onDelete={handleDeleteChip(value)}
                                    avatar={
                                      <Avatar sx={{ bgcolor: '#27A376' }}>{getInitials(displayName || value)}</Avatar>
                                    }
                                    sx={{
                                      height: 24,
                                      background: '#27A37614',
                                      '& .MuiChip-label': {
                                        fontSize: '0.75rem',
                                      },
                                      '& .MuiChip-deleteIcon': {
                                        width: 8,
                                        height: 8,
                                      },
                                      '& .MuiAvatar-root': {
                                        width: 24,
                                        height: 24,
                                        fontSize: '0.75rem',
                                        color: 'white',
                                        bgcolor: '#27A376',
                                      },
                                    }}
                                    deleteIcon={
                                      <IconButton
                                        onMouseDown={(event) => {
                                          event.stopPropagation();
                                        }}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    }
                                  />
                                )
                              );
                            })}
                          </Box>
                        );
                      }}
                      MenuProps={MenuProps}
                    >
                      {employees.length === 0 ? (
                        <MenuItem disabled>No options</MenuItem>
                      ) : (
                        employees.map((employee) => (
                          <MenuItem
                            key={employee.userId}
                            value={employee.userId.toString()}
                            style={{
                              fontWeight:
                                personName.indexOf(employee.userId.toString()) === -1
                                  ? theme.typography.fontWeightRegular
                                  : theme.typography.fontWeightMedium,
                            }}
                          >
                            <Avatar sx={{ mr: 1, width: 28, height: 28, fontSize: '0.8rem', bgcolor: '#27A376' }}>
                              {getInitials(isRtl ? employee.firstNameAr : employee.firstNameEn)}
                            </Avatar>
                            {isRtl
                              ? `${employee.firstNameAr}   ${employee.lastNameAr}`
                              : `${employee.firstNameEn} ${employee.lastNameEn}`}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    {touched.employees && errors.employees && (
                      <Typography color="error" variant="body2">
                        {errors.employees}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.active}
                        onChange={(e) => setFieldValue('active', e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Active"
                  />
                </Grid>
              </Grid>

              <RoleTabsUI
                tabsData={rolesData}
                setSelectedPermissions={setSelectedPermissions}
                filterActions={(page) => page.actions.filter((action) => action.isChecked === '1')}
              />

              <Grid item xs={12} md={12}>
                <Box display="flex" justifyContent="space-between" mt={3}>
                  <Button size="large" variant="outlined" sx={{ mr: 2 }} onClick={handleCancel}>
                    {t('General.cancel')}
                  </Button>
                  <LoadingButton loading={loading} size="large" type="submit" variant="contained">
                    {roleId ? t('General.submit') : t('General.submit')}
                  </LoadingButton>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>

        <Dialog
          open={openCancelDialog}
          onClose={() => handleCancelDialogClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to cancel?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleCancelDialogClose(false)} color="primary">
              No
            </Button>
            <Button onClick={() => handleCancelDialogClose(true)} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Layout>
  );
};

export default RoleForm;
