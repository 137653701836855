// src/components/StepOneForm.js
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Formik, Field, FieldArray } from 'formik';
import * as yup from 'yup';
import InfoIcon from '@mui/icons-material/Info';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  FormHelperText,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Paper,
  Select,
  Switch,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Tooltip,
  InputAdornment,
} from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import DeleteFileModal from '../../../components/deleteFileModal/DeleteFileModal';
import UploadFileOnChange from '../../../components/UploadFileOnChange.jsx/UploadFileOnChange';
import PreviewFile from '../../../components/previewFile/PreviewFile';

import {
  getRegionList,
  getFacilitiesList,
  getServicesList,
  getpostSaleServicesList,
  getListDocumentsByTargetData,
  getCitiesListWithRegion,
  getDistrictListWithCity,
} from '../../../network/apis';
import ClientHeadTable from '../../../components/clientHeadTable/ClientHeadTable';
import { updateFormData } from '../../../Store/features/projectForm/projectFormSlice';

import { ReactComponent as EditIcon } from '../../../theme/images/editTableIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../theme/images/deleteTableIcon.svg';

const CustomSwitch = ({ field, form, isViewMode, t }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  const handleChange = (event) => {
    setFieldValue(name, event.target.checked ? 'ACTIVE' : 'IN_ACTIVE');
  };

  return (
    <FormControlLabel
      disabled={isViewMode}
      control={<Switch checked={value === 'ACTIVE'} onChange={handleChange} />}
      label={t('General.Status')}
    />
  );
};

const ProjectDataForm = ({
  editData = {},
  isEditMode,
  isViewMode,
  handleNext,
  handleDelete,
  uploadedFilesUploaded,
  addItem,
  deleteItem,
  refetch,
  setRefetch,
  setSelectedFacilities,
  selectedFacilities,
  setSelectedServices,
  selectedServices,
  setSelectedPostSaleServices,
  selectedAfterSaleServices,
}) => {
  const focusOnSelect = useRef(null);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const dispatch = useDispatch();
  const [deleteFileData, setDeleteFileData] = useState({
    open: false,
    type: '',
    id: '',
    name: '',
  });
  const [changeVirtualTour, setChangeVirtualTour] = useState(editData?.virtualType !== 'LINK');
  const TABLE_HEAD = [
    { id: 'landmark-name-en', label: t('General.NameEn'), alignRight: false },
    { id: 'landmark-name-ar', label: t('General.NameAr'), alignRight: false },
    { id: 'landmark-distance', label: t('General.Distance'), alignRight: false },
    { id: 'landmark-distance-unit', label: t('General.DistanceUnit'), alignRight: false },
  ];
  if (!isViewMode) {
    TABLE_HEAD.push({ id: 'action', label: t('General.Action'), alignRight: false });
  }
  const [loading, setLoading] = useState(false);
  const formData = useSelector((state) => state.form.formData);
  // region states

  const [regionOptions, setRegionOptions] = useState([]);
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const getAllRegions = (page, search) => {
    setLoading(true);
    getRegionList(0, 100, search)
      .then((res) => {
        setRegionOptions(res.data);

        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (!dropdownOpened) {
      getAllRegions(0, '');
      setDropdownOpened(true);
    }
  }, [editData, dropdownOpened]);

  // city states

  const [citydropdownOpened, setCityDropdownOpened] = useState(false);
  const [citiesList, setCitiesList] = useState([]);

  const getCitiesWithRegion = (page, size, region) => {
    setLoading(true);
    getCitiesListWithRegion(page, size, region)
      .then((res) => {
        setCitiesList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    if (editData?.regionId) getCitiesWithRegion(0, 200, editData.regionId);
  }, [editData, citydropdownOpened]);

  // facilites states

  const [facilityDropdownOpened, setFacilityDropdownOpened] = useState(false);
  const [facilitiesOptions, setFacilities] = useState([]);

  const getAllFacilities = (page, search) => {
    setLoading(true);
    getFacilitiesList(page, search)
      .then((res) => {
        setFacilities(res.data);

        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (!facilityDropdownOpened) {
      getAllFacilities(0, '');
      setFacilityDropdownOpened(true);
    }
  }, [editData, facilityDropdownOpened]);

  // services states

  const [serviceDropdownOpened, setServiceDropdownOpened] = useState(false);
  const [servicesOptions, setServices] = useState([]);

  const getAllServicesRequest = (page, search) => {
    setLoading(true);
    getServicesList(page, search)
      .then((res) => {
        setServices(res.data);

        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (!serviceDropdownOpened) {
      getAllServicesRequest(0, '');
      setServiceDropdownOpened(true);
    }
  }, [editData, serviceDropdownOpened]);

  // after sale services states

  const [postSaleServiceDropdownOpened, setPostSaleServiceDropdownOpened] = useState(false);
  const [postSaleServicesOptions, setPostSaleServicesOptions] = useState([]);

  const getpostSaleServicesListRequest = (page, search) => {
    setLoading(true);
    getpostSaleServicesList(page, search)
      .then((res) => {
        setPostSaleServicesOptions(res.data);

        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (!postSaleServiceDropdownOpened) {
      getpostSaleServicesListRequest(0, '');
      setPostSaleServiceDropdownOpened(true);
    }
  }, [editData, serviceDropdownOpened]);

  // document type states

  const [documentTypeList, setDocumentTypeList] = useState([]);
  const getProjectDocumentType = () => {
    setLoading(true);
    getListDocumentsByTargetData('PROJECT')
      .then((res) => {
        setDocumentTypeList(res.data);

        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    getProjectDocumentType('PROJECT');
  }, []);

  // District states

  const [districtdropdownOpened, setDistrictDropdownOpened] = useState(false);
  const [districtList, setDistrictList] = useState([]);

  const getDistrictWithCity = (page, size, city, district) => {
    setLoading(true);
    getDistrictListWithCity(page, size, city, district)
      .then((res) => {
        setDistrictList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    if (editData?.cityId) getDistrictWithCity(0, 200, editData.cityId);
  }, [editData, districtdropdownOpened]);

  const [isBannerUploaded, setUploadedBanner] = useState(false);

  const distanceUnit = [
    {
      id: 1,
      value: 'M',
      label: t('General.Meters'),
    },
    {
      id: 2,
      value: 'K',
      label: t('General.Kilometers'),
    },
  ];
  const validationSchema = yup.object().shape({
    nameEn: yup.string().required(t('General.required_field')),
    nameAr: yup
      .string()
      .required(t('General.required_field'))
      .test('is-arabic-or-number-or-dash', 'Please enter Arabic text\nيرجي ادخال نص عربي', (value) => {
        // Regular expression to match Arabic characters, Arabic-Indic digits, regular digits, spaces, and dashes
        const arabicRegex = /^[\u0600-\u06FF\u0660-\u0669\s0-9-]+$/;

        // Regular expression to match English characters
        const englishRegex = /[A-Za-z]/;

        // Check if the value contains only Arabic characters, digits, spaces, and dashes, and does not contain English characters
        return arabicRegex.test(value) && !englishRegex.test(value);
      }),
    detailsEn: yup.string().required(t('General.required_field')),
    detailsAr: yup.string().required(t('General.required_field')),
    addressEn: yup.string().required(t('General.required_field')),
    addressAr: yup.string().required(t('General.required_field')),
    location: yup.string().required(t('General.required_field')).max(1000).min(10),
    totalArea: yup.number().required(t('General.required_field')),
    licenceId: yup.string().required(t('General.required_field')),
    totalBuilding: yup.number().required(t('General.required_field')),
    totalUnits: yup.number().required(t('General.required_field')),
    unitOffered: yup.number().required(t('General.required_field')),
  });

  return (
    <Formik
      initialValues={
        isEditMode
          ? {
              ...editData,
            }
          : {
              ...formData,
              states: 'ACTIVE',
            }
      }
      onSubmit={(values, { setSubmitting }) => {
        dispatch(
          updateFormData({
            ...values,
            virtualType: changeVirtualTour,
            facilities: selectedFacilities,
            services: selectedServices,
            afterSaleServices: selectedAfterSaleServices,
          })
        );
        dispatch(handleNext());
        setSubmitting(false);
      }}
      validate={() => {
        const errors = {};
        return errors;
      }}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, formik }) => (
        <form style={{ height: '100%' }} onSubmit={handleSubmit}>
          <Grid
            sx={{ direction: isRtl ? 'rtl' : 'ltr' }}
            rowSpacing={2}
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <>
              <Grid
                rowSpacing={2}
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                style={{
                  padding: '0.5rem 0.87rem 1rem',
                  border: '1px solid #efefef',
                  margin: '0.5rem',
                  borderRadius: '14px',
                  boxShadow: '3px 2px 5px #d6d5d5',
                }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Stack>
                    <h3 style={{ margin: '0', padding: '0', textAlign: isRtl ? 'right' : 'left' }}>
                      {t('ManageProject.projectData')}
                    </h3>
                  </Stack>
                </Grid>

                <Grid item xs={2} sm={4} md={3}>
                  <Typography variant="body1" gutterBottom>
                    {t('ManageProject.advertiseId')}
                  </Typography>
                  <TextField
                    name="advertiseId"
                    placeholder={t('ManageProject.enterAdvertiseId')}
                    required
                    fullWidth
                    value={values.advertiseId}
                    variant="outlined"
                    onChange={handleChange}
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip title={<Typography variant="body2">{t('General.auto_gentrate_text')} </Typography>}>
                            <InfoIcon />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1" gutterBottom>
                    {t('ManageProject.projectNameEn')} <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    name="nameEn"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        placeholder={`${t('General.enter')} ${t('ManageProject.projectNameEn')}`}
                        fullWidth
                        variant="outlined"
                        name="nameEn"
                        value={values.nameEn}
                        // onBlur={handleBlur}
                        error={touched.nameEn && Boolean(errors.nameEn)}
                        helperText={touched.nameEn && errors.nameEn}
                        disabled={isViewMode}
                        onChange={handleChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={5}>
                  <Typography variant="body1" gutterBottom>
                    {t('ManageProject.projectNameAr')} <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    render={({ field }) => (
                      <TextField
                        {...field}
                        name="nameAr"
                        placeholder={`${t('General.enter')} ${t('ManageProject.projectNameAr')}`}
                        required
                        fullWidth
                        value={values.nameAr}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={touched.nameAr && Boolean(errors.nameAr)}
                        helperText={
                          touched.nameAr &&
                          errors.nameAr && (
                            <span dangerouslySetInnerHTML={{ __html: errors.nameAr.replace('\n', '<br />') }} />
                          )
                        }
                        disabled={isViewMode}
                        onChange={handleChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.detailsEn')} <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    name="detailsEn"
                    placeholder={t('General.enterDetails')}
                    rows={5}
                    multiline
                    required
                    fullWidth
                    value={values.detailsEn}
                    disabled={isViewMode}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.detailsEn && Boolean(errors.detailsEn)}
                    helperText={touched.detailsEn && errors.detailsEn}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.detailsAr')} <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    render={({ field }) => (
                      <TextField
                        {...field}
                        name="detailsAr"
                        placeholder={t('General.enterDetails')}
                        rows={5}
                        multiline
                        required
                        fullWidth
                        value={values.detailsAr}
                        variant="outlined"
                        onBlur={handleBlur}
                        disabled={isViewMode}
                        error={touched.detailsAr && Boolean(errors.detailsAr)}
                        helperText={touched.detailsAr && errors.detailsAr}
                        onChange={handleChange}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={2} sm={6} md={4}>
                  <Stack>
                    <Typography variant="body1" gutterBottom>
                      {t('General.region')} <span style={{ color: 'red' }}>*</span>{' '}
                    </Typography>
                    <Select
                      labelId="regionId"
                      id="regionId"
                      onOpen={() => {
                        if (!dropdownOpened) {
                          getAllRegions(0, '');
                          setDropdownOpened(true);
                        }
                      }}
                      disabled={isViewMode}
                      value={values.regionId}
                      onChange={(event) => {
                        setFieldValue('regionId', event.target.value);
                      }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span style={{ color: '#9e9e9e' }}> {t('General.select')}</span>;
                        }
                        return isRtl
                          ? regionOptions?.content?.find((option) => option.id === selected)?.nameAr
                          : regionOptions?.content?.find((option) => option.id === selected)?.nameEn;
                      }}
                    >
                      {loading ? (
                        <MenuItem>
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : (
                        regionOptions?.content?.map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            {isRtl ? option.nameAr : option.nameEn}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={2} sm={6} md={4}>
                  <Stack>
                    <InputLabel id="cityId">
                      {t('General.City')} <span style={{ color: 'red' }}>*</span>{' '}
                    </InputLabel>
                    <Select
                      sx={{ mt: 1 }}
                      labelId="cityId"
                      id="cityId"
                      value={values.cityId}
                      disabled={isViewMode}
                      onOpen={() => {
                        getCitiesWithRegion(0, 200, values.regionId);
                      }}
                      onChange={(event) => {
                        setFieldValue('cityId', event.target.value);
                      }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span style={{ color: '#9e9e9e' }}> {t('General.select')}</span>;
                        }
                        return isRtl
                          ? citiesList?.content?.find((option) => option.id === selected)?.nameAr
                          : citiesList?.content?.find((option) => option.id === selected)?.nameEn;
                      }}
                    >
                      {loading ? (
                        <MenuItem>
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : (
                        citiesList?.content?.map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            {isRtl ? option.nameAr : option.nameEn}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    {touched.cityId && errors.cityId && <FormHelperText error>{errors.cityId}</FormHelperText>}
                  </Stack>
                </Grid>
                <Grid item xs={2} sm={6} md={4}>
                  <Stack>
                    <InputLabel id="districtId">
                      {t('General.district')} <span style={{ color: 'red' }}>*</span>{' '}
                    </InputLabel>
                    <Select
                      sx={{ mt: 1 }}
                      labelId="districtId"
                      id="districtId"
                      value={values.districtId}
                      onOpen={() => {
                        getDistrictWithCity(0, 200, values.cityId, values.districtId);
                      }}
                      onChange={(event) => {
                        setFieldValue('districtId', event.target.value);
                      }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span style={{ color: '#9e9e9e' }}> {t('General.select')}</span>;
                        }
                        return isRtl
                          ? districtList?.content?.find((option) => option.id === selected)?.nameAr
                          : districtList?.content?.find((option) => option.id === selected)?.nameEn;
                      }}
                    >
                      {loading ? (
                        <MenuItem>
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : (
                        districtList?.content?.map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            {isRtl ? option.nameAr : option.nameEn}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    {touched.districtId && errors.districtId && (
                      <FormHelperText error>{errors.districtId}</FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.addressEn')} <span style={{ color: 'red' }}>*</span>
                  </Typography>

                  <Field
                    render={({ field }) => (
                      <TextField
                        {...field}
                        name="addressEn"
                        required
                        fullWidth
                        disabled={isViewMode}
                        value={values.addressEn}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={touched.addressEn && Boolean(errors.addressEn)}
                        helperText={touched.addressEn && errors.addressEn}
                        onChange={handleChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.addressAr')}
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    render={({ field }) => (
                      <TextField
                        {...field}
                        name="addressAr"
                        required
                        fullWidth
                        value={values.addressAr}
                        variant="outlined"
                        onBlur={handleBlur}
                        disabled={isViewMode}
                        error={touched.addressAr && Boolean(errors.addressAr)}
                        helperText={touched.addressAr && errors.addressAr}
                        onChange={handleChange}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.GeoLocation')} <span style={{ color: 'red' }}>*</span>
                  </Typography>

                  <Field
                    render={({ field }) => (
                      <TextField
                        {...field}
                        name="location"
                        required
                        fullWidth
                        value={values.location}
                        disabled={isViewMode}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={touched.location && Boolean(errors.location)}
                        helperText={touched.location && errors.location}
                        onChange={handleChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.totalArea')} <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    render={({ field }) => (
                      <TextField
                        {...field}
                        name="totalArea"
                        required
                        fullWidth
                        value={values.totalArea}
                        disabled={isViewMode}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={touched.totalArea && Boolean(errors.totalArea)}
                        helperText={touched.totalArea && errors.totalArea}
                        onChange={handleChange}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.licenseId')}
                  </Typography>
                  <Field
                    render={({ field }) => (
                      <TextField
                        {...field}
                        name="licenceId"
                        required
                        fullWidth
                        value={values.licenceId}
                        variant="outlined"
                        disabled={isViewMode}
                        onBlur={handleBlur}
                        error={touched.licenceId && Boolean(errors.licenceId)}
                        helperText={touched.licenceId && errors.licenceId}
                        onChange={handleChange}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={2} sm={4} md={6}>
                  <Stack>
                    <InputLabel id="facilities"> {t('General.facilities')}</InputLabel>

                    <Select
                      sx={{ mt: 1 }}
                      name="facilities"
                      labelId="facilities"
                      multiple
                      disabled={isViewMode}
                      id="facilities"
                      value={selectedFacilities}
                      displayEmpty
                      input={<OutlinedInput label="Multiple Select" />}
                      onOpen={() => {
                        if (!facilityDropdownOpened) {
                          getAllFacilities(0, '');
                          setFacilityDropdownOpened(true);
                        }
                      }}
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;
                        setSelectedFacilities(value);
                      }}
                      renderValue={(selected) => {
                        if (selected?.length === 0) {
                          return <span style={{ color: '#9e9e9e' }}> {t('General.select')}</span>;
                        }

                        return selected
                          ?.map((value) =>
                            isRtl
                              ? facilitiesOptions?.content?.find((option) => option.id === value)?.nameAr || ''
                              : facilitiesOptions?.content?.find((option) => option.id === value)?.nameEn || ''
                          )
                          ?.join(', ');
                      }}
                    >
                      {loading ? (
                        <MenuItem>
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : (
                        facilitiesOptions?.content?.map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            {isRtl ? option.nameAr : option.nameEn}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Stack>
                    <InputLabel id="services">{t('General.services')}</InputLabel>
                    <Select
                      sx={{ mt: 1 }}
                      labelId="services"
                      name="services"
                      multiple
                      id="services"
                      value={selectedServices}
                      disabled={isViewMode}
                      displayEmpty
                      input={<OutlinedInput label="Multiple Select" />}
                      onOpen={() => {
                        if (!serviceDropdownOpened) {
                          getAllServicesRequest(0, '');
                          setServiceDropdownOpened(true);
                        }
                      }}
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;
                        setSelectedServices(value);
                      }}
                      renderValue={(selected) => {
                        if (selected?.length === 0) {
                          return <span style={{ color: '#9e9e9e' }}>{t('General.select')}</span>;
                        }

                        return selected
                          ?.map((value) =>
                            isRtl
                              ? servicesOptions?.content?.find((option) => option.id === value)?.nameAr || ''
                              : servicesOptions?.content?.find((option) => option.id === value)?.nameEn || ''
                          )
                          ?.join(', ');
                      }}
                    >
                      {loading ? (
                        <MenuItem>
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : (
                        servicesOptions?.content?.map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            {isRtl ? option.nameAr : option.nameEn}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </Stack>
                </Grid>

                <Grid item xs={2} sm={4} md={6}>
                  <Stack>
                    <InputLabel id="services">{t('General.postService')}</InputLabel>
                    <Select
                      sx={{ mt: 1 }}
                      disabled={isViewMode}
                      name="afterSaleServices"
                      labelId="afterSaleServicesIds"
                      multiple
                      id="afterSaleServicesIds"
                      value={selectedAfterSaleServices}
                      onOpen={() => {
                        if (!serviceDropdownOpened) {
                          getpostSaleServicesListRequest(0, '');
                          setPostSaleServiceDropdownOpened(true);
                        }
                      }}
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;
                        setSelectedPostSaleServices(value);
                      }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected?.length === 0) {
                          return <span style={{ color: '#9e9e9e' }}> {t('General.select')}</span>;
                        }

                        return selected
                          ?.map((value) =>
                            isRtl
                              ? postSaleServicesOptions?.content?.find((option) => option.id === value)?.nameAr || ''
                              : postSaleServicesOptions?.content?.find((option) => option.id === value)?.nameEn || ''
                          )
                          ?.join(', ');
                      }}
                      input={<OutlinedInput label="Multiple Select" />}
                    >
                      {loading ? (
                        <MenuItem>
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : (
                        postSaleServicesOptions?.content?.map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            {isRtl ? option.nameAr : option.nameEn}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.totalBuilding')}
                  </Typography>
                  <Field
                    render={({ field }) => (
                      <TextField
                        {...field}
                        name="totalBuilding"
                        required
                        disabled={isViewMode}
                        fullWidth
                        value={values.totalBuilding}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={touched.totalBuilding && Boolean(errors.totalBuilding)}
                        helperText={touched.totalBuilding && errors.totalBuilding}
                        onChange={handleChange}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                rowSpacing={2}
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                style={{
                  padding: '0.5rem 0.87rem 1rem',
                  border: '1px solid #efefef',
                  margin: '0.5rem',
                  borderRadius: '14px',
                  boxShadow: '3px 2px 5px #d6d5d5',
                }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Stack>
                    <h3 style={{ margin: '0', padding: '0' }}>{t('General.unitData')}</h3>
                  </Stack>
                </Grid>

                <Grid item xs={2} sm={4} md={6}>
                  <Stack>
                    <InputLabel id="unitType">{t('General.unitType')}</InputLabel>
                    <Select
                      sx={{ mt: 1 }}
                      labelId="unitType"
                      id="unitType"
                      value={values.unitType}
                      disabled={isViewMode}
                      onChange={(event) => {
                        handleChange({
                          target: {
                            name: 'unitType',
                            value: event.target.value,
                          },
                        });
                      }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span style={{ color: '#9e9e9e' }}>{t('General.select')}</span>;
                        }
                        return selected === 'ALL'
                          ? t('Units.all')
                          : selected === 'APPARTMENT '
                          ? t('Units.apartment')
                          : t('Units.villa');
                      }}
                    >
                      <MenuItem value="ALL">{t('Units.all')}</MenuItem>
                      <MenuItem value="APPARTMENT ">{t('Units.apartment')}</MenuItem>
                      <MenuItem value="VILLA ">{t('Units.villa')}</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.totalUnits')}
                  </Typography>
                  <Field
                    render={({ field }) => (
                      <TextField
                        {...field}
                        name="totalUnits"
                        required
                        fullWidth
                        value={values.totalUnits}
                        disabled={isViewMode}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={touched.totalUnits && Boolean(errors.totalUnits)}
                        helperText={touched.totalUnits && errors.totalUnits}
                        onChange={handleChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.unitOffered')}
                  </Typography>
                  <Field
                    render={({ field }) => (
                      <TextField
                        {...field}
                        disabled={isViewMode}
                        name="unitOffered"
                        required
                        fullWidth
                        value={values.unitOffered}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={touched.unitOffered && Boolean(errors.unitOffered)}
                        helperText={touched.unitOffered && errors.unitOffered}
                        onChange={handleChange}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                rowSpacing={2}
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                style={{
                  padding: '0.5rem 0.87rem 1rem',
                  border: '1px solid #efefef',
                  margin: '0.5rem',
                  borderRadius: '14px',
                  boxShadow: '3px 2px 5px #d6d5d5',
                }}
              >
                <Grid item xs={2} sm={4} md={6}>
                  <Stack>
                    <InputLabel id="reservationStatus">{t('General.recentProject')}</InputLabel>
                    <Select
                      sx={{ mt: 1 }}
                      name="recentProject"
                      labelId="recentProject"
                      id="recentProject"
                      value={values.recentProject}
                      disabled={isViewMode}
                      onChange={handleChange}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span style={{ color: '#9e9e9e' }}>{t('General.select')}</span>;
                        }
                        return selected === 'ACTIVE' ? t('General.Active') : t('General.InActive');
                      }}
                    >
                      <MenuItem value="ACTIVE">{t('General.Active')}</MenuItem>
                      <MenuItem value="IN_ACTIVE">{t('General.InActive')}</MenuItem>
                    </Select>
                  </Stack>
                </Grid>

                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.endPublishing')}
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      disabled={isViewMode}
                      label={t('General.endPublishing')}
                      value={values.endDate ? dayjs(values.endDate) : null}
                      onChange={(newValue) => {
                        const formattedDate = newValue.format('YYYY-MM-DD');
                        setFieldValue('endDate', formattedDate);
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                  {values.recentProject === 'ACTIVE' && !values.endDate && (
                    <Typography variant="body2" color="error">
                      {t('General.thisFieldIsRequired')}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={2} sm={4} md={6}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">{t('General.displayConfig')}</FormLabel>
                    <Field name="displayConfig">
                      {({ field }) => (
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name={field.name}
                          value={field.value}
                          onChange={(e) => {
                            setFieldValue(field.name, e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value="BANNER"
                            disabled={isViewMode}
                            control={<Radio />}
                            label={t('General.banner')}
                          />
                          {/* <FormControlLabel
                            value="RECENT"
                            disabled={isViewMode}
                            control={<Radio />}
                            label={t('General.recent')}
                          /> */}
                        </RadioGroup>
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.projectBanner')}
                  </Typography>
                  <UploadFileOnChange
                    setFieldValue={setFieldValue}
                    uploadedFilesUploaded={uploadedFilesUploaded}
                    viewMode={isViewMode}
                    type="PHOTO"
                    category="PROJECT"
                    multiple={false}
                    label={t('General.projectBanner')}
                    isViewMode={isViewMode}
                    fieldName="projectBannar"
                    maxSize={5000000}
                    acceptedTypes="image/*"
                    addItem={addItem}
                    deleteItem={deleteItem}
                  />
                  <Box>
                    {editData.projectBanner && uploadedFilesUploaded?.projectBannar?.length === 0 && (
                      <PreviewFile
                        file={{
                          path: editData.projectBanner,
                          displayName: editData?.projectBanner,
                          id: uploadedFilesUploaded?.projectBanner?.length,
                        }}
                      />
                    )}
                  </Box>
                  {(values.recentProject === 'ACTIVE' || values.displayConfig === 'BANNER') &&
                    !values.projectBanner &&
                    isBannerUploaded && (
                      <Typography variant="body2" color="error">
                        {t('General.thisFieldIsRequired')}
                      </Typography>
                    )}
                </Grid>
              </Grid>
              <Grid
                rowSpacing={2}
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                style={{
                  padding: '1rem 0.87rem 1rem 2.3rem',
                  border: '1px solid #efefef',
                  margin: '0.5rem',
                  borderRadius: '14px',
                  boxShadow: '3px 2px 5px #d6d5d5',
                }}
              >
                <Stack sx={{ flexDirection: isRtl ? 'row-reverse' : 'row' }}>
                  <h3
                    style={{
                      textAlign: isRtl ? 'right' : 'left',
                      marginTop: '0',
                      marginBottom: '0.5rem',
                      marginLeft: '0',
                      marginRight: '0',
                    }}
                  >
                    {t('General.landmarks')}
                  </h3>
                </Stack>
                <FieldArray name="nearestLandmarks">
                  {({ push, remove }) => {
                    return (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        {!isViewMode && (
                          <Grid
                            container
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            sx={{ width: '100%', marginBottom: '1rem' }}
                            spacing={1}
                          >
                            <Grid item xs={12} sm={6} md={3}>
                              <Stack>
                                <InputLabel id="landmarkNameEn">{t('General.nameEn')}</InputLabel>
                                <TextField
                                  id="landmarkNameEn"
                                  name="landmarkNameEn"
                                  value={values.landmarkNameEn}
                                  placeholder={t('General.enterNameEn')}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fullWidth
                                  variant="outlined"
                                />
                              </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <Stack>
                                <InputLabel id="landmarkNameAr">{t('General.nameAr')}</InputLabel>
                                <TextField
                                  id="landmarkNameAr"
                                  name="landmarkNameAr"
                                  value={values.landmarkNameAr}
                                  placeholder={t('General.enterNameAr')}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fullWidth
                                  variant="outlined"
                                />
                              </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <Stack>
                                <InputLabel id="distance">{t('General.distance')}</InputLabel>
                                <TextField
                                  id="distance"
                                  name="distance"
                                  type="number"
                                  value={values.distance}
                                  placeholder={t('General.enterDistance')}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fullWidth
                                  variant="outlined"
                                />
                              </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                              <Stack>
                                <InputLabel id="measure">{t('General.distanceUnit')} *</InputLabel>
                                <Select
                                  labelId="measure"
                                  ref={focusOnSelect}
                                  id="measure"
                                  name="measure"
                                  value={values.measure}
                                  onChange={(event) => setFieldValue('measure', event.target.value?.toString())}
                                  displayEmpty // This prop ensures the placeholder is shown when no value is selected
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return <em>{t('General.select')}</em>; // Placeholder text
                                    }
                                    const item = distanceUnit.find((option) => option.value === selected);
                                    return item?.label || <em>{t('General.select')}</em>;
                                  }}
                                >
                                  <MenuItem disabled value="">
                                    <em>{t('General.select')}</em>
                                  </MenuItem>
                                  {distanceUnit.map((option) => (
                                    <MenuItem key={option.id} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={1}>
                              {' '}
                              <IconButton
                                onClick={() => {
                                  if (
                                    values.landmarkNameEn &&
                                    values.landmarkNameAr &&
                                    values.distance &&
                                    values.measure
                                  ) {
                                    push({
                                      landmarkNameEn: values.landmarkNameEn,
                                      landmarkNameAr: values.landmarkNameAr,
                                      distance: values.distance,
                                      measure: values.measure,
                                    });
                                    setFieldValue('landmarkNameEn', '');
                                    setFieldValue('landmarkNameAr', '');
                                    setFieldValue('distance', '');
                                    setFieldValue('measure', '');
                                  }
                                }}
                                sx={{
                                  marginTop: '15px !important',
                                  width: '40px',
                                  height: '40px',
                                  borderRadius: '50%',
                                  backgroundColor: '#000',
                                  color: 'white',
                                  '&:hover': {
                                    backgroundColor: '#303f9f',
                                  },
                                }}
                              >
                                <AddCircleOutline />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}

                        <div
                          style={{
                            width: '100%',
                            maxWidth: '100%',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            maxHeight: '300px ',
                            overflowY: 'auto',
                          }}
                        >
                          <TableContainer component={Paper} sx={{ minWidth: 800 }}>
                            <Table>
                              <ClientHeadTable sortable={false} headLabel={TABLE_HEAD} />
                              <TableBody>
                                {values.nearestLandmarks?.map((row, index) => {
                                  return (
                                    <TableRow key={index} tabIndex={-1}>
                                      <TableCell component="th" scope="row">
                                        <Typography
                                          sx={{
                                            color: 'text.secondary',
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                          }}
                                          variant="subtitle2"
                                          noWrap
                                        >
                                          {row?.landmarkNameEn}
                                        </Typography>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <Typography
                                          sx={{
                                            color: 'text.secondary',
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                          }}
                                          variant="subtitle2"
                                          noWrap
                                        >
                                          {row?.landmarkNameAr}
                                        </Typography>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <Typography
                                          sx={{
                                            color: 'text.secondary',
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                          }}
                                          variant="subtitle2"
                                          noWrap
                                        >
                                          {row?.distance}
                                        </Typography>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <Typography
                                          sx={{
                                            color: 'text.secondary',
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                          }}
                                          variant="subtitle2"
                                          noWrap
                                        >
                                          {distanceUnit.find((item) => item.value === row?.measure)?.label}
                                        </Typography>
                                      </TableCell>
                                      {!isViewMode && (
                                        <TableCell component="th" align="left" scope="row">
                                          <Typography variant="subtitle2" noWrap>
                                            <Button sx={{ color: '#27A376' }}>
                                              <EditIcon
                                                onClick={() => {
                                                  remove(index);
                                                  const landmarks = values.nearestLandmarks[index];
                                                  setFieldValue('landmarkNameAr', landmarks.landmarkNameAr);
                                                  setFieldValue('landmarkNameEn', landmarks.landmarkNameEn);
                                                  setFieldValue('distance', landmarks.distance);
                                                  setFieldValue('measure', landmarks.measure);

                                                  if (focusOnSelect.current) {
                                                    focusOnSelect.current.scrollIntoView({
                                                      behavior: 'smooth',
                                                      block: 'center',
                                                    });
                                                  }
                                                }}
                                              />
                                            </Button>
                                            <Button sx={{ color: '#27A376' }}>
                                              <DeleteIcon onClick={() => remove(index)} />
                                            </Button>
                                          </Typography>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    );
                  }}
                </FieldArray>
              </Grid>
              <Grid
                rowSpacing={2}
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                style={{
                  padding: '0.5rem 0.87rem 1rem',
                  border: '1px solid #efefef',
                  margin: '0.5rem',
                  borderRadius: '14px',
                  boxShadow: '3px 2px 5px #d6d5d5',
                }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Stack>
                    <h3 style={{ margin: '0', padding: '0' }}>{t('General.otherData')}</h3>
                  </Stack>
                </Grid>

                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.projectPictures')}
                  </Typography>
                  <UploadFileOnChange
                    viewMode={isViewMode}
                    uploadedFilesUploaded={uploadedFilesUploaded}
                    multiple
                    type="PICTURE"
                    category="PROJECT"
                    label={t('General.projectPictures')}
                    fieldName="projectPictures"
                    maxSize={5000000}
                    acceptedTypes="image/*"
                    addItem={addItem}
                    deleteItem={deleteItem}
                  />
                  <Box>
                    {editData?.pictures &&
                      editData?.pictures?.map((file, index) => (
                        <PreviewFile
                          key={index}
                          ViewMode={isViewMode}
                          file={file}
                          onDelete={() =>
                            setDeleteFileData({
                              open: true,
                              id: file.id,
                              name: file.name,
                              type: 'pictures',
                            })
                          }
                        />
                      ))}
                  </Box>
                </Grid>

                <Grid item xs={2} sm={4} md={6}>
                  <Stack>
                    <Typography variant="body1" gutterBottom>
                      {t('General.projectStatus')}
                    </Typography>
                    <Select
                      labelId="projectStatus"
                      id="projectStatus"
                      value={values.projectStatus}
                      disabled={isViewMode}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        handleChange({
                          target: {
                            name: 'projectStatus',
                            value: newValue,
                          },
                        });

                        const currentFormData =
                          JSON.parse(
                            localStorage.getItem('projectFormData') || sessionStorage.getItem('projectFormData')
                          ) || {};
                        const updatedFormData = {
                          ...currentFormData,
                          projectStatus: newValue,
                        };
                        localStorage.setItem('projectFormData', JSON.stringify(updatedFormData));
                        sessionStorage.setItem('projectFormData', JSON.stringify(updatedFormData));
                      }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span style={{ color: '#9e9e9e' }}>{t('General.select')}</span>;
                        }
                        return selected === 'TRUE' ? t('Units.complete') : t('Units.incomplete');
                      }}
                    >
                      <MenuItem value="TRUE">{t('Units.complete')}</MenuItem>
                      <MenuItem value="FALSE">{t('Units.incomplete')}</MenuItem>
                    </Select>
                  </Stack>
                </Grid>

                <Grid item xs={2} sm={4} md={6}>
                  <Stack>
                    <InputLabel id="reservationStatus">{t('General.reservationStatus')}</InputLabel>
                    <Select
                      sx={{ mt: 1 }}
                      labelId="reservationStatus"
                      id="reservationStatus"
                      value={values.reservationStatus}
                      disabled={isViewMode}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        handleChange({
                          target: {
                            name: 'reservationStatus',
                            value: newValue,
                          },
                        });

                        const currentFormData =
                          JSON.parse(
                            localStorage.getItem('projectFormData') || sessionStorage.getItem('projectFormData')
                          ) || {};
                        const updatedFormData = {
                          ...currentFormData,
                          reservationStatus: newValue,
                        };
                        localStorage.setItem('projectFormData', JSON.stringify(updatedFormData));
                        sessionStorage.setItem('projectFormData', JSON.stringify(updatedFormData));
                      }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span style={{ color: '#9e9e9e' }}>{t('General.select')}</span>;
                        }
                        return selected === 'FREE' ? t('Units.free') : t('Units.reserved');
                      }}
                    >
                      <MenuItem value="FREE">{t('Units.free')}</MenuItem>
                      <MenuItem value="TOTALLY_RESERVED">{t('Units.reserved')}</MenuItem>
                    </Select>
                  </Stack>
                </Grid>

                <Grid item xs={2} sm={4} md={6}>
                  <Stack>
                    <InputLabel id="projectDataStatus">{t('General.projectDataStatus')}</InputLabel>
                    <Select
                      sx={{ mt: 1 }}
                      labelId="projectDataStatus"
                      id="projectDataStatus"
                      disabled={isViewMode}
                      value={values.projectDataStatus}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        handleChange({
                          target: {
                            name: 'projectDataStatus',
                            value: newValue,
                          },
                        });
                      }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span style={{ color: '#9e9e9e' }}>{t('General.select')}</span>;
                        }
                        return selected === 'COMPLETE' ? t('Units.complete') : t('Units.incomplete');
                      }}
                    >
                      <MenuItem value="COMPLETE">{t('Units.complete')}</MenuItem>
                      <MenuItem value="INCOMPLETE">{t('Units.incomplete')}</MenuItem>
                    </Select>
                  </Stack>
                </Grid>

                <Grid
                  style={{ marginTop: '0.9rem', paddingLeft: '1rem' }}
                  rowSpacing={2}
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ fontWeight: 600, marginRight: '10px' }} color={'#111827'}>
                        {t('General.supportedDocuments')}
                      </Typography>
                      {loading ? (
                        <MenuItem>
                          <CircularProgress sx={{ color: '#111827' }} size={24} />
                        </MenuItem>
                      ) : (
                        documentTypeList?.map((option, index) => (
                          <Box
                            key={index}
                            sx={{
                              width: '91px',
                              height: '30px',
                              backgroundColor: 'rgba(231, 76, 60, 0.10)',
                              '&:hover': {
                                backgroundColor: 'primary.dark',
                                opacity: [0.9, 0.8, 0.7],
                                color: 'white',
                              },
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: '8px',
                              marginRight: '5px',
                              cursor: 'pointer',
                              fontSize: '10px',
                              fontWeight: '500',
                              lineHeight: '21px',
                            }}
                          >
                            {isRtl ? option.nameAr : option.nameEn}
                          </Box>
                        ))
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={2} sm={4} md={6}>
                    <UploadFileOnChange
                      viewMode={isViewMode}
                      uploadedFilesUploaded={uploadedFilesUploaded}
                      type="ATTACHMENT"
                      category="PROJECT"
                      label={t('General.supportedDocuments')}
                      fieldName="projectAttacments"
                      maxSize={5000000}
                      acceptedTypes="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      addItem={addItem}
                      deleteItem={deleteItem}
                    />
                    <Box>
                      {editData?.attachments &&
                        uploadedFilesUploaded?.projectAttacments?.length === 0 &&
                        editData?.attachments?.map((file, index) => (
                          <PreviewFile
                            key={index}
                            // ViewMode={isViewMode}
                            file={file}
                            // onDelete={() =>
                            //   setDeleteFileData({
                            //     open: true,
                            //     id: file.id,
                            //     name: file.name,
                            //     type: 'attachment',
                            //   })
                            // }
                          />
                        ))}
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Stack direction={'row'} alignItems={'center'}>
                    <Switch
                      checked={changeVirtualTour}
                      name="virtualType"
                      onChange={() => {
                        handleChange('virtualType');
                        setChangeVirtualTour(!changeVirtualTour);
                      }}
                    />
                    <Typography variant="body1">
                      {changeVirtualTour ? t('Units.virtualTour') : t('General.virtualTourURL')}
                    </Typography>
                  </Stack>
                  {changeVirtualTour ? (
                    <>
                      <UploadFileOnChange
                        uploadedFilesUploaded={uploadedFilesUploaded}
                        viewMode={isViewMode}
                        multiple
                        type="VIRTUAL_TOUR"
                        category="PROJECT"
                        label={t('Units.virtualTour')}
                        fieldName="virtualTour"
                        maxSize={5000000}
                        acceptedTypes="image/*"
                        addItem={addItem}
                        deleteItem={deleteItem}
                      />
                      {editData?.virtualTour && uploadedFilesUploaded?.virtualTour?.length === 0 && (
                        <PreviewFile
                          file={{
                            path: editData?.virtualTour,
                            displayName: editData?.virtualTour,
                            id: uploadedFilesUploaded?.virtualTour?.length,
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <TextField
                      sx={{ marginBottom: 2 }}
                      name="virtualLink"
                      placeholder={t('General.virtualTourURL')}
                      fullWidth
                      value={values.virtualLink}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.primaryPhoto')}
                  </Typography>
                  <UploadFileOnChange
                    setFieldValue={setFieldValue}
                    viewMode={isViewMode}
                    uploadedFilesUploaded={uploadedFilesUploaded}
                    type="PHOTO"
                    category="PROJECT"
                    multiple={false}
                    label={t('General.primaryPhoto')}
                    fieldName="primaryPhoto"
                    maxSize={5000000}
                    acceptedTypes="image/*"
                    addItem={addItem}
                    deleteItem={deleteItem}
                  />
                  <Box>
                    {editData?.primaryPhoto && uploadedFilesUploaded?.primaryPhoto?.length === 0 && (
                      <PreviewFile
                        file={{
                          path: editData?.primaryPhoto,
                          displayName: editData?.primaryPhoto,
                          id: uploadedFilesUploaded?.primaryPhoto?.length,
                        }}
                      />
                    )}
                  </Box>
                </Grid>

                <Grid style={{ marginTop: '5px' }} item xs={2} sm={12} md={12}>
                  <FormGroup>
                    <Field isViewMode={isViewMode} name="states" t={t} component={CustomSwitch} />
                  </FormGroup>
                </Grid>
              </Grid>
            </>
            <DeleteFileModal
              open={deleteFileData.open}
              setOpen={deleteFileData}
              handelClose={() => setDeleteFileData({ ...deleteFileData, open: false })}
              onDelete={() => {
                handleDelete(deleteFileData?.id, deleteFileData?.type, deleteFileData?.name);
                setDeleteFileData({ ...deleteFileData, open: false });
                setRefetch(!refetch);
              }}
            />
          </Grid>
          <input type="hidden" name="updateUi" onChange={() => setFieldValue('updateUi', true)} />

          <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Button
              sx={{ mt: 4, border: '1px solid #111827' }}
              size="large"
              onClick={() => {
                navigate(-1);
              }}
            >
              {t('General.cancel')}
            </Button>
            <Button sx={{ mt: 4, border: '1px solid #111827' }} size="large" type="submit" variant="contained">
              {t('General.next')}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ProjectDataForm;
