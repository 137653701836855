import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Skeleton,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  SvgIcon,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CityReqTable } from '../sections/@dashboard/admins';
import Layout from '../components/Layout/Layout';
import SearchComponet from '../components/search/Search';

import { getlistAdmins, deleteRegion } from '../network/apis';
import { hasPermission } from '../utils/permissions';

import { ReactComponent as DropdownIcon } from '../theme/images/deopdownIcon.svg';

export default function Admins() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const isRtl = i18n.language === 'ar';

  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [clientReqList, setClientReqList] = useState([]);
  const [search, setSearch] = useState('');
  const [status, setChangeStatus] = useState('');

  const handleChange = (e) => {
    setChangeStatus(e.target.value);
  };

  const clientRequests = (page, search, status) => {
    setLoading(true);
    getlistAdmins(page, search, status)
      .then((res) => {
        setClientReqList(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching admin list:', error);
        // Display a toast message with a generic error message
        toast.error(error?.response?.data?.MESSAGE);
        setLoading(false);
      });
  };

  useEffect(() => {
    clientRequests(page, search, status);
  }, [page, search, status]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleDelete = (id) => {
    deleteRegion(id)
      .then((res) => {
        if (res.data.STATUS === 'FAILED' || res.data.STATUS === '0') {
          toast.error(res.data.MESSAGE);
        } else toast.success(res.data.MESSAGE);
        // Reload the cities list
        clientRequests(page, search);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.MESSAGE);
      });
  };
  const CustomArrowIcon = ({ isRtl }) => (
    <SvgIcon style={{ transform: 'rotate(-360deg)', color: '#637381' }}>
      <path d="M7 10l5 5 5-5z" />
    </SvgIcon>
  );

  const handleNavigate = () => {
    navigate('/dashboard/admin/add');
  };

  const sideMenu = useSelector((state) => state.login?.sideMenu || []);
  const hasSearchAdmintPermission = hasPermission(sideMenu, '/dashboard/manage-employees', 'Search For Admin');
  const hasAddAdminPermission = hasPermission(sideMenu, '/dashboard/manage-employees', 'Add New Admin');
  const hasEditProjectPermission = hasPermission(sideMenu, '/dashboard/manage-employees', 'Edit Admin Profile');

  return (
    <>
      <Helmet>
        <title> {t('Employees.manageEmoployees')}</title>
      </Helmet>

      <Layout>
        <Grid
          style={{ direction: isRtl ? 'rtl' : 'ltr' }}
          marginBottom={1}
          display="flex"
          alignItems={'center'}
          justifyContent="space-between"
          md={6}
          xs={12}
        >
          <Typography sx={{ fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#111827' }}>
            {t('Employees.manageEmoployees')}
          </Typography>
          {hasAddAdminPermission && (
            <Button
              onClick={handleNavigate}
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '10px',
                padding: '21px 24px',
                minWidth: '164px',
                height: '56px',
              }}
            >
              {' '}
              <Typography sx={{ display: 'flex', gap: 1 }}>
                <span> + </span> <span>{t('Employees.addEmployee')}</span>
              </Typography>
            </Button>
          )}
        </Grid>
        <Grid
          style={{ direction: isRtl ? 'rtl' : 'ltr' }}
          mb={2}
          rowSpacing={2}
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {hasSearchAdmintPermission && (
            <Grid item xs={2} sm={4} md={4}>
              <SearchComponet onSearch={setSearch} style={{ width: '100%' }} />
            </Grid>
          )}

          <Grid item xs={2} sm={4} md={6}>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl fullWidth>
                <Select
                  labelId={t('General.status')}
                  id="status"
                  IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                  sx={{
                    paddingLeft: isRtl ? '10px' : '0px',
                    paddingRight: isRtl ? '0px' : '10px',
                    direction: isRtl ? 'rtl' : 'ltr',
                  }}
                  onChange={(event) => {
                    handleChange({
                      target: {
                        name: 'status',
                        value: event.target.value,
                      },
                    });
                  }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <span style={{ color: '#9e9e9e' }}>{t('General.select')}</span>;
                    }
                    return selected === '1' ? (
                      <span> {t('General.Active')} </span>
                    ) : (
                      <span>{t('General.InActive')}</span>
                    );
                  }}
                >
                  <MenuItem sx={{ direction: isRtl ? 'rtl' : 'lrt' }} value="1">
                    {t('General.Active')}
                  </MenuItem>
                  <MenuItem sx={{ direction: isRtl ? 'rtl' : 'lrt' }} value="0">
                    {t('General.InActive')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <>
              <CityReqTable
                handleChangePage={handleChangePage}
                page={page}
                requests={clientReqList}
                onDelete={handleDelete}
                hasEditProjectPermission={hasEditProjectPermission}
              />
            </>
          </>
        )}
      </Layout>
    </>
  );
}
