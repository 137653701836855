import * as React from "react";
import { toast } from "react-toastify";

import TextField from "@mui/material/TextField";
import {
  Grid,
  Typography,
  Button,
  Input,
  Stack,
  CircularProgress,
} from "@mui/material";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Modal from "../Modal";
import UploadFileOnChange from "../UploadFileOnChange.jsx/UploadFileOnChange";
import { addOurServices } from "../../network/apis";

export default function AddOurServiceModal({
  open,
  handleClose,
  ourServicesListApi,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [uploadedFiles] = React.useState({
    ourServiceImage: [],
  });
  // Add Item Function
  const addItem = (fieldName, items) => {
    if (!uploadedFiles[fieldName]) {
      uploadedFiles[fieldName] = [];
    }
    items.forEach((item) => {
      if (!uploadedFiles[fieldName].includes(item)) {
        uploadedFiles[fieldName].push(item);
      }
    });
  };

  // Delete Item Function
  const deleteItem = (fieldName, item) => {
    if (uploadedFiles[fieldName]) {
      uploadedFiles[fieldName] = uploadedFiles[fieldName].filter(
        (i) => i !== item
      );
    }
  };
  const addItemCallback = React.useCallback((fieldName, items) => {
    addItem(fieldName, items);
  }, []);

  const deleteItemCallback = React.useCallback((fieldName, item) => {
    deleteItem(fieldName, item);
  }, []);

  // Handle Add Our Service

  const handleAddOurService = async (values) => {
    setLoading(true);
    const res = await addOurServices({
      nameEn: values.nameEn,
      nameAr: values.nameAr,
      link: values.link,
      startDate: values.startDate,
      endDate: values.endDate,
      image: uploadedFiles?.ourServiceImage?.map((item) => item.id),
    });

    if (res?.data?.STATUS === "SUCCESS") {
      toast.success(res?.data?.MESSAGE);
      setLoading(false);
      ourServicesListApi(0, "");
      handleClose();
      setLoading(false);
    } else {
      toast.error(res?.data?.MESSAGE);
      setLoading(false);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      open={open}
      title={t("addOurServices.addOurServices")}
    >
      <Formik
        initialValues={{
          nameEn: "",
          nameAr: "",
          link: "",
          startDate: "",
          endDate: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleAddOurService(values);
          handleClose();
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
        }) => {
          return (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Grid
                  container
                  spacing={{ xs: 2, md: 1 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{
                    height: "100%",
                    padding: "20px",
                    borderBottom: "1px solid #E5E5E5",
                  }}
                >
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("addOurServices.nameEn")}
                    </Typography>
                    <TextField
                      name="nameEn"
                      required
                      fullWidth
                      value={values.nameEn}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.nameEn && Boolean(errors.nameEn)}
                      helperText={touched.nameEn && errors.nameEn}
                    />
                  </Grid>{" "}
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("addOurServices.nameAr")}
                    </Typography>
                    <TextField
                      name="nameAr"
                      required
                      fullWidth
                      value={values.nameAr}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.nameAr && Boolean(errors.nameAr)}
                      helperText={touched.nameAr && errors.nameAr}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={12}>
                    <Typography variant="body1" gutterBottom>
                      {t("addOurServices.image")}
                    </Typography>
                    <UploadFileOnChange
                      uploadedFilesUploaded={uploadedFiles}
                      type="PHOTO"
                      category="PROJECT"
                      multiple={false}
                      label={t("addOurServices.image")}
                      fieldName="ourServiceImage"
                      maxSize={5000000}
                      acceptedTypes="image/*"
                      addItem={addItemCallback}
                      deleteItem={deleteItemCallback}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={12}>
                    <Typography variant="body1" gutterBottom>
                      {t("addOurServices.link")}
                    </Typography>
                    <TextField
                      name="link"
                      required
                      multiline
                      rows={1}
                      fullWidth
                      value={values.link}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.link && Boolean(errors.link)}
                      helperText={touched.link && errors.link}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("addOurServices.startDate")}
                    </Typography>
                    <Input
                      sx={{
                        color: "#030229",
                        paddingX: "10px",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "21.82px",
                        backgroundColor: "#F9F9F9",
                        borderRadius: "10px",
                        width: "100%",
                        height: "50px",
                        border: "1px solid #E5E5E5",
                        outlineColor: "#18A5F1",
                        "&::before": {
                          borderBottom: "none",
                        },
                        "&::after": {
                          borderBottom: "none",
                          display: "none",
                        },
                        "&:hover": {
                          borderBottom: "none",
                        },
                      }}
                      name="startDate"
                      type="date"
                      required
                      value={values.startDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.startDate && Boolean(errors.startDate)}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("addOurServices.endDate")}
                    </Typography>
                    <Input
                      sx={{
                        color: "#030229",
                        paddingX: "10px",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "21.82px",
                        backgroundColor: "#F9F9F9",
                        borderRadius: "10px",
                        width: "100%",
                        height: "50px",
                        border: "1px solid #E5E5E5",
                        outlineColor: "#18A5F1",
                        "&::before": {
                          borderBottom: "none",
                        },
                        "&::after": {
                          borderBottom: "none",
                          display: "none",
                        },
                        "&:hover": {
                          borderBottom: "none",
                        },
                      }}
                      name="endDate"
                      type="date"
                      required
                      value={values.endDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.endDate && Boolean(errors.endDate)}
                    />
                  </Grid>
                </Grid>
                <Stack
                  sx={{ width: "100%" }}
                  padding={2}
                  direction="row"
                  justifyContent="end"
                  alignItems="end"
                  gap={2}
                >
                  <Button
                    sx={{ mt: 4, border: "1px solid #111827" }}
                    size="large"
                    type="button"
                    onClick={handleClose}
                  >
                    {" "}
                    {t("General.cancel")}
                  </Button>
                  <Button
                    sx={{ mt: 4, border: "1px solid #111827" }}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {" "}
                    {loading ? <CircularProgress /> : t("General.submit")}
                    {}
                  </Button>
                </Stack>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
}
