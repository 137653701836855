import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Typography } from "@mui/material";
import { ReactComponent as CancelIcon } from "../theme/images/close-square.svg";

export default function TransitionsModal({
  open,
  title,
  handleClose,
  children,
}) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      open={open}
      onClose={handleClose}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            borderRadius: "16px",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: "32px",
            maxHeight: "90%",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "24px",
              borderBottom: "1px solid #EFEFF5",
            }}
          >
            <Typography
              id="transition-modal-title"
              sx={{ fontWeight: "700", lineHeight: "32px", fontSize: "24px" }}
            >
              {title}
            </Typography>
            <Box sx={{ cursor: "pointer" }}>
              {" "}
              <CancelIcon onClick={handleClose} />
            </Box>
          </Box>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
}
