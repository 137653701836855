import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Grid,
  Typography,
  CardMedia,
  Stack,
  Button,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
  IconButton,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { hasPermission } from '../../../utils/permissions';
import CustomAccordion from './CustomAccordion';
import LoadingOverlay from '../../../components/loading-overlay/LoadingOverlay';
import ModalData from '../../../components/modal-section/ModalSection';
import PreviewFile from '../../../components/previewFile/PreviewFile';
import {
  getListAdminsNames,
  getUnitsDetials,
  assignOwnershipRequest,
  updateOwnershipRequest,
  viewOwnershipRequestDetials,
} from '../../../network/apis';

const MainContent = ({ children }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { nextAction, requestId, paymentStatus, action, fromPage } = location.state || '';
  const sideMenu = useSelector((state) => state.login?.sideMenu || []);

  const hasAssignPermission = hasPermission(sideMenu, '/dashboard/ownership-requests', 'Assign Request');
  const hasFinishPermission = hasPermission(sideMenu, '/dashboard/ownership-requests', 'Finish Request');
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
  const [isFinancialDetailsOpen, setIsFinancialDetailsOpen] = useState(false);
  const [isOwnerTabOpen, setIsOwnerTabOpen] = useState(false);
  const [paymentStatusValue, setPaymentStatusValue] = useState(paymentStatus || '');

  const [paymentStatusError, setPaymentStatusError] = useState(paymentStatus === 'Not Completed');
  const [selectedValue, setSelectedValue] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();
  const handleOpenAssignModal = () => setIsAssignModalOpen(true);
  const handleCloseAssignModal = () => setIsAssignModalOpen(false);
  const [employeesList, setEmployeesList] = useState([]);
  const [detailsData, setDetails] = useState([]);
  const [detailsOwnerShipReq, setOwnerShipReq] = useState([]);
  const reservationInvoice = detailsOwnerShipReq?.REQUEST_DETAILS?.reservationInvoice || '';
  const requestStatus = detailsOwnerShipReq?.REQUEST_DETAILS?.requestStatus || '';

  const handleInvoiceClick = () => {
    localStorage.setItem('reservationInvoice', reservationInvoice);
    sessionStorage.setItem('reservationInvoice', reservationInvoice);

    window.open('/dashboard/resrvation-request');
  };
  const handleOpenFinishModal = () => setIsFinishModalOpen(true);
  const handleCloseFinishModal = () => setIsFinishModalOpen(false);

  const handleFinish = () => {
    if (paymentStatusValue !== '1') {
      setPaymentStatusError('You must complete your payment ');
      setIsFinancialDetailsOpen(true);

      return;
    }
    handleOpenFinishModal();
  };

  const handleUpdateOwnership = async () => {
    setLoading(true);
    try {
      const assignedOwnerShipData = { paymentStatus: paymentStatusValue, id: requestId };
      const response = await updateOwnershipRequest(assignedOwnerShipData);

      if (response.data.STATUS === 'SUCESS' || response.data.STATUS === '1') {
        handleCloseFinishModal();
        toast.success(response.data.MESSAGE || 'Ownership updated successfully');
        navigate('/dashboard/ownership-requests');
      } else if (response.data.STATUS === 'FAILED' || response.data.STATUS === '0') {
        handleCloseFinishModal();
        toast.error(response.data.MESSAGE || 'Failed to update ownership request');
      }
    } catch (error) {
      console.error('Failed to update ownership request:', error);
      toast.error(error.response.data.MESSAGE || 'Failed to update ownership request');
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handlePaymentStatusChange = (event) => {
    setPaymentStatusValue(event.target.value);

    if (event.target.value === '1') {
      setPaymentStatusError('');
    }
  };

  const handleAssign = () => {
    handleOpenAssignModal();
  };

  const handleNoButtonClick = () => {
    handleCloseFinishModal();
    navigate('/dashboard/ownership-requests');
  };

  const handleAssignCycle = async () => {
    setLoading(true);
    try {
      const assignedOwnerShipData = { id: requestId, assignedUserId: selectedValue };
      await assignOwnershipRequest(assignedOwnerShipData);
      toast.success('Ownership assigned successfully');
      handleCloseAssignModal();

      setSelectedValue('');
      navigate('/dashboard/ownership-requests');
    } catch (error) {
      console.error('Failed to assign ownership request:', error);
      toast.error('Failed to assign ownership request');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    handleCloseAssignModal();
  };

  const fetchUnitDetails = async () => {
    try {
      const { data } = await getUnitsDetials(id);
      setDetails(data?.data);
    } catch (error) {
      console.error('Failed to fetch unit details:', error);
      toast.error('Failed to fetch unit details');
    } finally {
      setLoading(false);
    }
  };

  const fetchViewOwnershipRequestDetials = async () => {
    try {
      const { data } = await viewOwnershipRequestDetials(requestId);
      setOwnerShipReq(data);
    } catch (error) {
      console.error('Failed to fetch ownership details:', error);
      toast.error('Failed to fetch ownership details');
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployeesList = async () => {
    try {
      const { data } = await getListAdminsNames();
      setEmployeesList(data);
    } catch (error) {
      console.error('Failed to fetch employees:', error);
      toast.error('Failed to fetch employees');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      fetchUnitDetails();
    } else {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchEmployeesList();
  }, []);

  useEffect(() => {
    fetchViewOwnershipRequestDetials();
  }, []);

  const getStatusMessage = (status) => {
    switch (status) {
      case '0':
        return t('ownershipRequest.underProcessing');
      case '1':
        return t('ownershipRequest.finished');
      case '-1':
        return t('ownershipRequest.cancelled');
      default:
        return 'Unknown status';
    }
  };

  const assignModalButtons = [
    {
      label: t('ownershipRequest.cancel'),
      sx: {
        color: 'black',
        width: '126px',
        height: '48px',
        padding: '21px',
        borderRadius: '10px',
      },
      onClick: handleCancel,
      variant: 'outlined',
    },

    {
      label: t('ownershipRequest.assign'),
      sx: {
        width: '126px',
        height: '48px',
        padding: '21px',
        borderRadius: '10px',
      },
      color: 'primary',
      onClick: handleAssignCycle,
      variant: 'contained',
    },
  ];

  const finishModalButtons = [
    {
      label: t('ownershipRequest.no'),
      sx: {
        color: 'black',
        width: '126px',
        height: '48px',
        padding: '21px',
        borderRadius: '10px',
      },
      onClick: handleNoButtonClick,
      variant: 'outlined',
    },
    {
      label: t('ownershipRequest.yes'),
      sx: {
        width: '126px',
        height: '48px',
        padding: '21px',
        borderRadius: '10px',
      },
      color: 'primary',
      onClick: handleUpdateOwnership,
      variant: 'contained',
    },
  ];

  const body1Style = {
    color: '#687588',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22.4px',
    '&:hover': {
      color: '#555E66',
    },
  };
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Box display={'flex'} sx={{ alignItems: 'center', gap: 2, width: '100%' }}>
        <IconButton sx={{ m: 0, p: 0 }} onClick={handleBackClick} color="primary">
          <ArrowBackIosIcon />
        </IconButton>
        <Typography sx={{ ml: '0 !important' }} variant="h4" gutterBottom>
          {t('ownershipRequest.detailsOwnerShipRequest')}
        </Typography>
      </Box>
      <CustomAccordion
        title={t('ownershipRequest.unitData')}
        content={
          <Grid container spacing={2}>
            {/* <Grid item xs={6} md={3}>
              <Typography variant="body1" sx={body1Style} gutterBottom>
                {t('ownershipRequest.planImage')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={9}>
              <Grid container spacing={2}>
                {detailsData?.pictures?.map((image, index) => (
                  <Grid item xs={3} key={index}>
                    <CardMedia
                      component="img"
                      height="120"
                      width="200"
                      sx={{ borderRadius: '10px' }}
                      image={image?.path}
                      alt={image.name}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid> */}

            {/* <Grid item xs={6} md={3}>
              <Typography variant="body1" sx={body1Style} gutterBottom>
                {t('ownershipRequest.virtualTour')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <CardMedia
                    component="img"
                    height="120"
                    width="200"
                    sx={{ borderRadius: '10px' }}
                    image={detailsData?.virtualTour}
                    alt={`Virtual tour image `}
                  />
                </Grid>
              </Grid>
            </Grid> */}

            <Grid item xs={6} md={3}>
              <Typography variant="body1" sx={body1Style} gutterBottom>
                {t('ownershipRequest.projectName')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="span">
                {isRtl
                  ? detailsOwnerShipReq?.REQUEST_DETAILS?.categoryNameAr
                  : detailsOwnerShipReq?.REQUEST_DETAILS?.categoryNameEn || ''}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body1" sx={body1Style} gutterBottom>
                {t('ownershipRequest.northernBorder')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body2">
                {isRtl ? detailsData?.northernBoardAr : detailsData?.northernBoardEn}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body1" sx={body1Style} gutterBottom>
                {t('ownershipRequest.unitName')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body2">{isRtl ? detailsData?.nameAr : detailsData?.nameEn}</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body1" sx={body1Style} gutterBottom>
                {t('ownershipRequest.southernBorder')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body2">
                {isRtl ? detailsData?.southernBoardAr : detailsData?.southernBoardEn}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body1" sx={body1Style} gutterBottom>
                {t('ownershipRequest.unitId')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body2">{detailsData?.id}</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body1" sx={body1Style} gutterBottom>
                {t('ownershipRequest.westernBorder')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body2">
                {isRtl ? detailsData?.easternBoardAr : detailsData?.westernBoardEn}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body1" sx={body1Style} gutterBottom>
                {t('ownershipRequest.unitType')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body2">{t(`ownershipRequest.${detailsData?.type}`)}</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body1" sx={body1Style} gutterBottom>
                {t('ownershipRequest.easternBorder')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body2">
                {isRtl ? detailsData?.southernBoardAr : detailsData?.easternBoardEn}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body1" sx={body1Style} gutterBottom>
                {t('ownershipRequest.unitArea')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body2">{detailsData?.area}</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body1" sx={body1Style} gutterBottom>
                {t('ownershipRequest.purpose')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body2">{t(`ownershipRequest.${detailsData?.purpose}`)}</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body1" sx={body1Style} gutterBottom>
                {t('ownershipRequest.facade')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body2">{t(`ownershipRequest.${detailsData?.facade}`)}</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body1" sx={body1Style} gutterBottom>
                {t('ownershipRequest.email')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body2">{detailsData?.email}</Typography>
            </Grid>
            <>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.whatsappNumber')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{detailsData?.whatsappNumber}</Typography>
              </Grid>

              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.mobileNumber')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{detailsData?.mobile}</Typography>
              </Grid>

              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.pieceDataStatus')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{t(`ownershipRequest.${detailsData?.pieceDataStatus}`)}</Typography>
              </Grid>

              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.pieceStatus')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">
                  {detailsData?.pieceStatus
                    ? detailsData?.pieceStatus === 'TRUE'
                      ? t('General.Active')
                      : t('General.InActive')
                    : ''}
                </Typography>
              </Grid>

              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.reservationStatus')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{t(`ownershipRequest.${detailsData?.reservationStatus}`)}</Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.supportedDocs')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Box>
                  {detailsData?.attachments &&
                    detailsData?.attachments?.map((file, index) => (
                      <>
                        <PreviewFile key={index} ViewMode file={file} />
                      </>
                    ))}
                </Box>
              </Grid>
            </>
          </Grid>
        }
      />
      <CustomAccordion
        title={t('ownershipRequest.financialData')}
        sx={{ borderBottom: '1px solid #F1F2F4' }}
        open={isFinancialDetailsOpen}
        onChange={() => setIsFinancialDetailsOpen(!isFinancialDetailsOpen)}
        content={
          <>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.piecePrice')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{detailsData?.piecePrice}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.realEstateTax')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{detailsData?.realEstateTax}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.commissionFees')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{detailsData?.commissionFees}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.commissionFeesVat')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{detailsData?.commissionFeesVat}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.ownershipFees')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{detailsData?.ownershipFees}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.meterPrice')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{detailsData?.meterPrice}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.totalPrice')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{detailsData?.totalPrice}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid sx={{ cursor: 'pointer' }} onClick={handleInvoiceClick} item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.invoiceId')}
                </Typography>
              </Grid>
              <Grid sx={{ cursor: 'pointer' }} onClick={handleInvoiceClick} item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {reservationInvoice}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.requestStatus')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{getStatusMessage(requestStatus)}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.paymentStatus')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                {paymentStatusValue === 'Not Completed' ||
                detailsOwnerShipReq?.REQUEST_DETAILS?.paymentStatus.toString() === '0' ? (
                  <FormControl fullWidth error={!!paymentStatusError}>
                    <Select
                      labelId="paymentStatus"
                      id="paymentStatus"
                      value={paymentStatusValue}
                      onChange={handlePaymentStatusChange}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span style={{ color: '#9e9e9e' }}>{t('ownershipRequest.paymentStatus')}</span>;
                        }
                        return selected === '1' ? t('ownershipRequest.COMPLETE') : t('ownershipRequest.INCOMPLETE');
                      }}
                    >
                      <MenuItem value="1">{t('ownershipRequest.COMPLETE')}</MenuItem>
                      <MenuItem value="0">{t('ownershipRequest.INCOMPLETE')}</MenuItem>
                    </Select>
                    {paymentStatusError && <FormHelperText>{paymentStatusError}</FormHelperText>}
                  </FormControl>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '4px 16px',
                      backgroundColor: '#E7F7EF',
                      borderRadius: '8px',
                      width: '88px',
                    }}
                  >
                    <Typography
                      sx={{ color: '#0CAF60', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                      variant="subtitle2"
                      textAlign={'center'}
                      noWrap
                    >
                      {t('ownershipRequest.COMPLETE')}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </>
        }
      />

      <CustomAccordion
        title={t('ownershipRequest.BuyerDetails')}
        sx={{ borderBottom: '1px solid #F1F2F4' }}
        open={isOwnerTabOpen}
        onChange={() => setIsOwnerTabOpen(!isOwnerTabOpen)}
        content={
          <>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.buyerName')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">
                  {isRtl
                    ? detailsOwnerShipReq?.BUYER_DETAILS?.buyerNameAr
                    : detailsOwnerShipReq?.BUYER_DETAILS?.buyerNameEn}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.phone')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{detailsOwnerShipReq?.BUYER_DETAILS?.phone}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.nationalId')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{detailsOwnerShipReq?.BUYER_DETAILS?.nationalId}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.nationality')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">
                  {detailsOwnerShipReq?.BUYER_DETAILS?.nationality === '113' ? 'Saudi Arabia' : 'Other'}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={body1Style} gutterBottom>
                  {t('ownershipRequest.dateOfBirth')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="body2">{detailsOwnerShipReq?.BUYER_DETAILS?.dateOfBirth}</Typography>
              </Grid>
            </Grid>
          </>
        }
      />

      {loading ? <p>{<LoadingOverlay />}</p> : children}

      <Stack sx={{ width: '100%', mt: 4 }} direction="row" justifyContent="end" alignItems="end" spacing={2}>
        {hasFinishPermission && (action === 'FINISH' || nextAction === 'FINISH') ? (
          <Button
            sx={{
              mt: 4,
              backgroundColor: '#F1F2F4',
              color: '#323B49',
              fontSize: '14px',
              borderRadius: '10px',
              padding: '21px 24px',
            }}
            size="large"
            type="button"
            onClick={handleFinish}
          >
            {t('ownershipRequest.finish')}
          </Button>
        ) : (
          ''
        )}

        {hasAssignPermission && (action === 'ASSIGN' || nextAction === 'ASSIGN') ? (
          <LoadingButton
            sx={{ mt: 4, fontSize: '14px', borderRadius: '10px', padding: '21px 24px' }}
            loading={loading}
            size="large"
            onClick={handleAssign}
            type="submit"
            variant="contained"
          >
            {t('ownershipRequest.assign')}
          </LoadingButton>
        ) : (
          ''
        )}

        {/* {(hasAssignPermission && nextAction === 'ASSIGN') ||
        (hasAssignPermission && fromPage === 'notifications' && action === 'ASSIGN') ? (
          <LoadingButton
            sx={{ mt: 4, fontSize: '14px', borderRadius: '10px', padding: '21px 24px' }}
            loading={loading}
            size="large"
            onClick={handleAssign}
            type="submit"
            variant="contained"
          >
            Assign
          </LoadingButton>
        ) : (
          ''
        )} */}
      </Stack>

      <ModalData
        open={isAssignModalOpen}
        onClose={handleCloseAssignModal}
        title={t('ownershipRequest.AssignRequest')}
        selectLabel={t('ownershipRequest.ownerShipAssigned')}
        description={t('ownershipRequest.assignText')}
        selectOptions={employeesList}
        requestId={requestId}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        onSelectChange={handleSelectChange}
        buttons={assignModalButtons}
        hasAssignPermission={hasAssignPermission}
        hasFinishPermission={hasFinishPermission}
      />
      <ModalData
        open={isFinishModalOpen}
        selectOptions={[]}
        onClose={handleCloseFinishModal}
        title={t('ownershipRequest.textConfirm')}
        description=""
        buttons={finishModalButtons}
        showWarningIcon
        hasAssignPermission={hasAssignPermission}
        hasFinishPermission={hasFinishPermission}
      />
    </Box>
  );
};

export default MainContent;
